import Link from 'next/link'
import Image from 'next/image'
import { FaAppStore, FaGooglePlay } from 'react-icons/fa'
import { Button } from '@nextui-org/react'

const Features = () => <>
  <div className='w-auto ml-4 mr-4 md:w-[800px] md:ml-auto md:mr-auto' id='features'>
    <h2 className='mb-4 text-2xl text-center'>An Awesome Way to Track Group Challenges</h2>
    
    Use Challenge Hound to create and track virtual challenges. You can create challenges based on a variety of activity types and structure them in a variety of ways.
    
    <div className='grid grid-cols-3 gap-1 md:gap-4 mt-8 mb-8 text-sm md:text-base'>
      <ul className='list-disc list-inside'>
        <li>Running</li>
        <li>Jogging</li>
        <li>Biking</li>
        <li>Cycling</li>
        <li>Hiking</li>
        <li>Walking</li>
        <li>Elliptical</li>
        <li>Wheelchair</li>
        <li>Tennis</li>
        <li>E-bike</li>
        <li>Pilates</li>
      </ul>
      <ul className='list-disc list-inside'>
        <li>Rowing</li>
        <li>Kayaking</li>
        <li>Inline Skating</li>
        <li>Alpine Skiing</li>
        <li>Snowboarding</li>
        <li>Nordic Ski</li>
        <li>Skating</li>
        <li>Handcycle</li>
        <li>Canoeing</li>
        <li>Golf</li>
        <li>Tai Chi</li>
      </ul>
      <ul className='list-disc list-inside'>
        <li>Yoga</li>
        <li>Meditation</li>
        <li>Workouts</li>
        <li>Online Classes</li>
        <li>Weight Training</li>
        <li>Swim</li>
        <li>Stand Up Paddle</li>
        <li>Skateboard</li>
        <li>Snowshoe</li>
        <li>Rock Climbing</li>
        <li>Football</li>
      </ul>
    </div>

    <div className='mb-8'>You can also create a challenge around your <div className='text-primary inline'>own activity types</div> such as gardening, chores, kindness, or anything else you can think up.</div> 
    
    With Challenge Hound, you can create <div className='text-primary inline'>distance</div> challenges, <div className='text-primary inline'>elevation</div> challenges, <div className='text-primary inline'>duration</div> challenges (log a specified amount of time), <div className='text-primary inline'>activity count</div> challenges (log a specified number of activities), and <div className='text-primary inline'>ranking</div> challenges (log as much as possible to climb the ranks). Challenge Hound also supports <div className='text-primary inline'>point-based challenges</div>, which enables you to allocate points per activity types.

    <div className='text-center mt-8'>
      <Link href={'/signup'}><Button size='lg' color='primary'>Try it out. Create a Challenge!</Button></Link>
    </div>

    <div style={{ marginTop: '40px' }}>Challenge Hound supports <div className='text-primary inline'>individual</div> challenges, as well as <div className='text-primary inline'>team</div> challenges. In individual challenges, each participant completes the full challenge goal. In team challenges, the team&apos;s activities are aggregated to complete the challenge goal together.</div>

    <div style={{ marginTop: '20px' }}>Challenge Hound also supports <div className='text-primary inline'>virtual race</div> challenges. Set any distance, choose various allowed activity types, and Challenge Hound provides a real-time race results leaderboard. The fastest activities logged win. Challenge Hound automatically qualifies activities and selects the fastest.</div>

    <div className='mt-16'>
      <h2 className='mb-4 text-2xl text-center'>Simple Integration with Apps</h2>
      <div className='flex flex-row justify-center items-center gap-2'>
        <div><Image width={80} height={80} src='/apple-health-logo.png' alt='Apple Health' /></div>
        <div><Image width={80} height={80} src='/uacf-run.png' alt='Map My Run - Under Armour Connected Fitness' /></div>
        <div><Image width={80} height={80} src='/fitbit.png' alt='Fitbit' /></div>
        <div><Image width={80} height={80} src='/health-connect-logo.png' alt='Health Connect' /></div>
        <div><Image width={80} height={80} src='/uacf-ride.png' alt='Map My Ride - Under Armour Connected Fitness' /></div>
        <div><Image width={80} height={80} src='/coros.png' alt='Coros' /></div>
        <div><Image width={80} height={80} src='/uacf-walk.png' alt='Map My Walk - Under Armour Connected Fitness' /></div>
        <div><Image width={80} height={80} src='/garmin-app-icon.png' alt='Garmin Connect' /></div>
        <div><Image width={80} height={80} src='/uacf-fitness.png' alt='Map My Fitness - Under Armour Connected Fitness' /></div>
        <div><Image width={80} height={80} style={{ borderRadius: '10px' }} src='/strava-logo.png' alt='Strava' /></div>
      </div>
    </div>  

    <div className='mt-16'>
      <h2 className='mb-4 text-2xl text-center'>Trusted By Thousands of Organizations</h2>
      The Challenge Hound platform is used by non-profits, corporations, schools, churches, event companies, retail, athletic clubs, and for family/friend challenges. It is used for wellness, team building, races, training, fundraising, brand-awareness, and for friendly competition and motivation.
      <div className='grid grid-cols-4 gap-4 justify-center items-center mt-10'>
        <div><a href='http://www.5peaks.com/' target='_new'><Image src={'/customer-logos/5-peaks.png'} width={125} height={125} alt={'5 peaks'} /></a></div>
        <div><a href='https://apartostudent.com/' target='_new'><Image src={'/customer-logos/aparto.jpg'} width={125} height={54} alt={'Aparto'} /></a></div>
        <div><a href='https://specialolympicspa.org/' target='_new'><Image src={'/customer-logos/sopa.png'} width={125} height={119} alt={'Special Olympics of Pennsylvania'} /></a></div>
        <div><a href='https://www.midnightrunners.com/' target='_new'><Image src={'/customer-logos/midnight-runners.jpg'} width={125} height={125} alt={'Midnight Runners'} /></a></div>
        <div><a href='http://www.curesearch.org/' target='_new'><Image src={'/customer-logos/cure-search.png'} width={125} height={102} alt={'Cure Search'} /></a></div>
        <div><a href='https://dysautonomiainternational.salsalabs.org/racetobeatpots2021/index.html' target='_new'><Image src={'/customer-logos/race-to-beat-pots.png'} width={125} height={116} alt={'Race to Beat POTS'} /></a></div>
        <div><a href='https://www.runnersedgemt.com/' target='_new'><Image src={'/customer-logos/runners-edge.png'} width={125} height={125} alt={'Runners Edge'} /></a></div>
        <div><a href='https://navitrails.com/' target='_new'><Image src={'/customer-logos/navitrails.png'} width={135} height={33} alt={'Navitrails'} /></a></div>
      </div>
    </div> 
    
    <div className='mt-12'>
      <h2 className='mb-4 text-2xl text-center'>Map Based Virtual Challenges</h2>
      Add an interactive map to any Challenge Hound distance challenge and track the challenge progress along a virtual route. 
      With team challenges, a single team icon will progress along the map route. With individual challenges, each participant
      will progress along the route. 
    </div> 

    <div className='mt-12 max-w-full'><Image src={'/mapped-challenges.png'} alt='Virtual challenges on a map' width={800} height={400} className='w-full' /></div>

    <h2 className='mt-12 mb-4 text-2xl text-center'>Challenge Hound Core Features</h2>
    <ul className='list-disc list-outside ml-6'>
      <li>Create personalized distance, elevation, duration, activity count, or ranking challenges</li>
      <li>Create challenges based on a variety of activities</li>
      <li>Every participant has personalized challenge dashboard with their progress</li>
      <li>Individual based challenges - each participant achieves the challenge objective</li>
      <li>Team based challenges - everyone in aggregate reaches the challenge objective</li>
      <li>Team vs Team challenges - which team can aggregate more</li>
      <li>Create virtual races for any distance and any activity types - fastest activities win</li>
      <li>Automatically track the challenge progress</li>
      <li>Receive emails after each activity with challenge status updates</li>
      <li>Full historic challenge archive</li>
      <li>Participant challenge trophy case</li>
      <li>Manually add activities directly in Challenge Hound</li>
      <li>Automatically sync activities with Garmin, Under Armour Connected Fitness (MapMyRun, MapMyRide, MapMyWalk, MapMyFitness), Fitbit, Coros, and Strava</li> 
    </ul>

    <div className='mt-12'><Image src={'/progress-graph.2.png'} alt='The best way to track group challenges.' width={800} height={437} /></div>

    <div className='grid grid-cols-1 md:grid-cols-2 mt-12'>
      <div className='flex justify-center'><Image src={'/leader-board.1.png'} alt='leader board' width={500} height={333} /></div>
      <div className='flex justify-center'><Image src={'/percent-done.2.png'} alt='percent done' width={300} height={302} /></div>
    </div>
  
    <div>
      <h2 className='mb-4 text-2xl text-center'>A Partner, Not Just a Platform</h2>
      Thousands of challenges have been set up in Challenge Hound. We&apos;ve got the expertise and the experience to help you create and run great challenges and races.
      We know what works, what motivates, and what engages participants in great challenges. Consider Challenge Hound not only a challenge platform to create and 
      track your challenges, but also a partner in helping you create amazing events.
    </div>

    <div style={{ marginTop: '60px' }}>
        <h2 className='mb-4 text-2xl text-center'>Available on Web, iOS, and Android</h2>
        <div className='grid grid-cols-2'>
          <div className='flex justify-center'><a href='https://apps.apple.com/us/app/challenge-hound/id6448111660' target='_new'><FaAppStore className='text-secondary' style={{ fontSize: '70' }} /></a></div>
          <div className='flex justify-center'><a href='https://play.google.com/store/apps/details?id=com.mobile1.challengehound' target='_new'><FaGooglePlay className='text-secondary' style={{ fontSize: '70' }} /></a></div>
        </div>
    </div>

    <div className='mt-12'>
      <h2 className='mb-4 text-2xl text-center'>Challenge Hound Works Behind the Scenes</h2>
      Once you join a challenge, Challenge Hound will <b>automatically</b> pull down your activities from the leading activity tracking Apps and update the challenge. You&apos;ll get an email from Challenge Hound with your challenge progress and everyone else in the challenge can see the results online. There is also an option to manually enter activities as well.
    </div>

    <div className='text-center mt-12'>
      <Link href={'/signup'}><Button size='lg' color='primary'>Get Started. Create a Challenge!</Button></Link>
    </div>

  </div>
</>


export default Features
